import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import "@/assets/css/reset.scss"
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import 'normalize.css'
import moment from 'moment'

import {download} from "@/axios";
Vue.prototype.download = download

Vue.prototype.$moment = moment
Vue.config.productionTip = false
Vue.filter('dataFormat', function (value, fmt) {
  let getDate = new Date(value)
  let o = {
    'M+': getDate.getMonth() + 1,
    'd+': getDate.getDate(),
    'h+': getDate.getHours(),
    'm+': getDate.getMinutes(),
    's+': getDate.getSeconds(),
    'q+': Math.floor((getDate.getMonth() + 3) / 3),
    S: getDate.getMilliseconds(),
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (getDate.getFullYear() + '').substr(4 - RegExp.$1.length),
    )
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1
          ? o[k]
          : ('00' + o[k]).substr(('' + o[k]).length),
      )
    }
  }
  return fmt
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
