<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
import { getRole, projectUserRole } from "@/utils/index"
export default {
  mounted () {
    let href = window.location.href
    let token = href.split("token=")[1]
    if (token) {
      localStorage.setItem("token", token)
    }
  },
  created () {
    getRole().then(res => {
      if (res.code === 200) {
        localStorage.setItem("info", JSON.stringify(res.rows[0]))
      } else {
        localStorage.setItem("info", undefined)
      }

    })
    projectUserRole().then(res => {
      if (res.code === 200) {
        localStorage.setItem("userType", JSON.stringify(res.data.userRole))
        console.log(res)
      }

    })


  }
}
</script>
<style >
</style>
